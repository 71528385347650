
.heroSection-container {
    width: 90%;
    margin-left: 4rem;
    margin-top: 10% !important;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15rem !important;
}

.ui.header {
    font-family: 'Crimson Pro', serif;
}

.herosection-text {
    font-size: 16px;
}