.about-us-container {
    display: flex;
}

.image-section {
    flex: 50%;
    padding-top: 10rem;
    padding-left: 10rem;
}

.info-section {
    flex: 50%;
    padding-top: 10rem;
    padding-right: 10rem;
    margin-left: 5rem;
    font-size: 20px;
    font-family: 'Crimson Pro', serif;
}

.image-section img {
    height: 25rem;
    width: 160rem;
}

.left-p {
    padding-left: 10rem;
    font-size: 20px;
    font-family: 'Crimson Pro', serif;
    padding-right: 10rem;
    margin-top: 2rem;
}


@media screen and (max-width: 1500px) {
    .image-section img {
        height: 27rem;
        width: 160rem;
    }
  }


  @media screen and (max-width: 1200px) {
    .about-us-container {
        display: block;
    }
    
    .image-section {
        padding-bottom: 0;
        padding-top: 10rem;
        padding-left: 5rem;
        padding-right: 5rem;
    }
    
    .info-section {
        padding-top: 3rem;
    }

    .left-p {
        padding-left: 5rem;
    }
  }


  
  @media screen and (max-width: 700px) {

    .image-section {
        height: 10rem;
        width: 90%;
    }

    .info-section {
        padding-top: 30rem;
    }
  }

  @media screen and (max-width: 500px) {

    .image-section img {
        height: 10rem;
        width: 100%;
    }

    .info-section {
        padding-top: 13rem;
    }

    .info-section p {
        padding: 0
    }

    .left-p {
        padding-left: 5rem;
    }
  }