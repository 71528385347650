* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #e3e2dd;
  font-family: 'Crimson Pro', serif;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Crimson Pro', serif;
  background-color: #e3e2dd;
}

h1, h2, h3, p {
  font-family: 'Crimson Pro', serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
