.container {
    display: grid;
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
    width: 50%;
    margin: auto;
}

.inner, .outer {
    grid-area: 1 / 1;
    height: 500px;
    width: 500px;
    border: 2px solid green;
}

.inner {
    margin-top: 100px;
    height: 350px;
    width: 250px;
    border: 2px solid blue;
    z-index: 99;
    border: none;
}

.inner img {
    width: 600px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
}

.outer {
    margin-left: 130px;
}

.outer h2 {
    color: #73756f;
    padding-top: 20px;
    font-weight: bold;
}

.outer p {
    margin-left: 140px;
    margin-right: 20px;
    margin-top: 45px;
    font-size: 20px;
    font-weight: bold;
    color: #575454;
}


.mobile-info h2{
    color: #73756f;
    padding-top: 20px;
    font-weight: bold;
}

.mobile-info p {
    margin-top: 25px;
    font-size: 20px;
    font-weight: bold;
    color: #575454;
}


.program {
    display: flex;              
    justify-content: space-around;
    margin-top: 20rem;
    width: 80%;
    margin: auto;
  }
  
  .program-item {
    flex: 1;                   
    text-align: center;       
    padding: 1rem;            
    border: 1px solid #ccc;
    height: 40rem;
    width: 25rem;
  }

  .item-1 {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    background-color: #73756f;
  }

  .program-item h2,
  .program-item h1 {
    background-color: #73756f;
    color: #e3e2dd;
  }

  .program-item h2 {
    margin-top: 3rem;
  }





  .container {
    max-width: 100vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 35px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 15px;
  }


  
  .container-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    margin-bottom: 5rem;
  }


  @media (max-width: 1200px) {
    .container-cards {
        flex-direction: column;
    }

    .card {
        margin-bottom: 5rem !important;
    }
}

  
  .card {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 0 auto;
    background: #000;
    border-radius: 15px;
    box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);
  }
  
  .face {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: none;
  }

  .face.face1 {
    box-sizing: border-box;
    padding: 20px;
  }
  
  .java {
    background-color: #73756f;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
  }


  .face.face2 {
    transition: 0.5s;
  }
  
  .face.face1 h2 {
    margin: 0;
    padding: 0;
  }
  
  .face.face2 h2 {
    margin: 0;
    padding: 0;
    font-size: 10em;
    color: #fff;
    transition: 0.5s;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  .card:hover .face.face2 {
    height: 60px;
    /* font-size: 2em; */
  }
  
  .card:nth-child(1) .face.face2 {
    background-image: linear-gradient(40deg, #fffc00 0%, #fc00ff 45%, #00fffc 100%);
    border-radius: 15px;
  }
  
  .card:nth-child(2) .face.face2 {
    background-image: linear-gradient(40deg, #fc00ff 0%, #00fffc 45%, #fffc00 100%);
    border-radius: 15px;
  }
  
  .card:nth-child(3) .face.face2 {
    background-image: linear-gradient(40deg, #00fffc 0%, #fc00ff 45%, #fffc00 100%);
    border-radius: 15px;
  }
  

@media (max-width: 1000px) {
    .outer {
        height: 500px;
        width: 400px;
    }
}


@media (max-width: 900px) {
    .container {
        margin-right: 35%;
    }
}

@media (max-width: 700px) {
    .container {
        margin-right: 40%;
    }
}