.left {
    flex: '0 0 50%';
    background-color: #e3e2dd;
    padding: '20px'; 
    margin-top: '16px';
}

.right {
    flex: '0 0 30%';
    background-color: #e3e2dd;
    padding: '20px'; 
    margin-top: '16px';
    width: 30rem;
}


.title {
    font-size: 44px;
    color: #73756f;
}

.description-a {
    font-size: 18px;
    color: #73756f;
    height: auto;
}

.description-b {
    font-size: 18px;
    color: #73756f;
    height: auto;
    font-weight: bold;
}

.dates {
    font-size: 26px;
    margin-top: 2rem;
}

.dates span {
    background-color: #73756f;
    color: #e3e2dd;
    width: 20% !important;
    padding: 10px;
    margin-right: 20px;
    border: 2px solid #e3e2dd;
    border-radius: 14px;
}



.address {
    margin-top: 4rem;
}

.address span {
    color:#73756f;
    font-size: 22px;
    margin-left: 1rem;
}

.pay span {
    color:#73756f;
    font-size: 22px;
}

.pay {
    margin-top: 1rem;
}

.rez {
    color:#73756f;
    font-size: 40px;
    margin-top: 2rem;
}

.mail {
    color:#73756f;
    font-size: 22px;
    margin-left: 1rem;
}


@media screen and (max-width: 1400px) {
    .title {
        font-size: 38px;
        color: #73756f;
    }

    .dates {
        font-size: 20px;
    }
    
    .dates span {
        font-size: 20px;
    }
    
    .rez {
        font-size: 34px;
    }
    
    .mail {
        font-size: 20px;
    }   
}

@media screen and (max-width: 1100px) {
    .title {
        font-size: 34px;
        color: #73756f;
    }

    .dates {
        font-size: 18px;
    }
    
    .dates span {
        font-size: 18px;
    }
    
    .rez {
        font-size: 30px;
    }
    
    .mail {
        font-size: 18px;
    }   
}



@media screen and (max-width: 1000px) {

    .left {
        flex: 0 0 50%;
        margin-right: 0.6rem;
    }
    
    .right {
        flex: 0 0 60%;
    }

    .title {
        font-size: 30px;
        color: #73756f;
    }

    .dates {
        font-size: 16px;
    }
    
    .dates span {
        font-size: 16px;
    }
    
    .rez {
        font-size: 26px;
    }
    
    .mail {
        font-size: 16px;
    }   
}




@media screen and (max-width: 900px) {

    .left {
       display: flex;
       justify-content: center;
       margin-bottom: 5rem;
    }
    
    .right {
       text-align: center;
       /* margin-right: 5rem; */
       margin: auto;
    }

    .title {
        font-size: 30px;
        color: #73756f;
    }

    .dates {
        font-size: 16px;
    }
    
    .dates span {
        font-size: 16px;
    }
    
    .rez {
        font-size: 26px;
    }
    
    .mail {
        font-size: 16px;
    }   
}
