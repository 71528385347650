

img {
  max-width: 100%;
  height: auto;
}


h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 0;
}

.card-hover {
  width: 360px;
  height: 500px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px -10px rgba(245, 144, 144, 0.08);
}

.card-hover:hover .card-hover__content {
  background-color: #73756f;
  bottom: 100%;
  transform: translateY(100%);
  padding: 50px 60px;
  transition: all 0.35s cubic-bezier(.1,.72,.4,.97);
}

.card-hover:hover .card-hover__link {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.3s 0.35s cubic-bezier(.1,.72,.4,.97);
  background-color: #73756f;
}

.card-hover:hover img {
  transform: scale(1);
  transition: 0.35s 0.1s transform cubic-bezier(.1,.72,.4,.97);
}

.card-hover__content {
  width: 100%;
  text-align: center;
  background-color: #73756f;
  padding: 0 60px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(0);
  transition: all 0.35s 0.35s cubic-bezier(.1,.72,.4,.97);
  will-change: bottom, background-color, transform, padding;
  z-index: 1;
}

.card-hover__content::before,
.card-hover__content::after {
  content: '';
  width: 100%;
  height: 120px;
  background-color: inherit;
  position: absolute;
  left: 0;
  z-index: -1;
  
  background-color: #73756f;
}

.card-hover__content::before {
  top: -80px;
  clip-path: ellipse(60% 80px at bottom center);
  
  background-color: #73756f;
}

.card-hover__content::after {
  bottom: -80px;
  clip-path: ellipse(60% 80px at top center);
  
  background-color: #73756f;
}

.card-hover__title {
  font-size: 1.5rem;
  margin-bottom: 1em;
  color:'#e3e2dd';
  background-color: #73756f;
}

.card-hover__title span {
  background-color: #73756f;
  color:'#e3e2dd';
}

.card-hover__text {
  font-size: 16px;
  background-color: #73756f;
  height: 10px;
}

.card-hover__link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 10%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  opacity: 0;
  padding: 10px;
  transition: all 0.35s;
  background-color: #73756f;
  color:'#e3e2dd';
  font-size: 30px;
}

.card-hover__link p {
    background-color: #73756f;
    color:'#e3e2dd';
}

.learn {
    background-color: #73756f;
    color:'#e3e2dd';
}

.card-hover__link:hover svg {
  transform: translateX(4px);  
  background-color: #73756f;
}

.card-hover__link svg {
  width: 18px;
  margin-left: 4px;
  transition: transform 0.3s;
  
  background-color: #73756f;
}

.card-hover__extra {
  height: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  background-color: #86b971;
  padding: 80px;
  bottom: 0;
  z-index: 0;
  color: #dee8c2;
  transform: translateY(100%);
  transition: transform 0.35s;
}

.card-hover__extra span {
  color: #2d7f0b;
}

.span-hover {
    background-color: #73756f;
}
