
.card {
    opacity: 0.8; 
    transition: opacity 06.s ease-in-out; 
  }
  
  .card:hover {
    opacity: 2;
  }


  @media screen and (max-width: 650px) {
    .card .subtitle {
      font-size: 10px;
    }
  }