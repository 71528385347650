.location {
    width: 80%;
    margin: auto;
    padding: 80px 0;
  }
  .location iframe {
    width: 100%;
  }
  
  .contact-us {
    width: 80%;
    margin: auto;
  }
  .contact-col {
    flex-basis: 48%;
    margin-bottom: 30px;
  }
  
  .contact-col div {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .contact-col span {
    margin-top: -40px;
  }
  
  .contact-col div .fa {
    font-size: 28px;
    color: #f44336;
    margin: 10px;
    margin-right: 30px;
  }
  
  .contact-col div p {
    padding: 0;
  }
  .contact-col div h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
  }
  .contact-col input,
  .contact-col textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }
  
  .footer-link {
    text-decoration: none;
    color: #777;
  }
  