body {
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  .advancedHeader,
  .advancedFooter {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 1em;
  }
  
  .advancedMain {
    padding: 20px;
  }
  
  h2 {
    color: rgb(251, 110, 7);
    text-align: center;
  }
  .advancedProfile {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 40px;
    width: 70%;
    margin: auto;
    margin-top: 60px;
  }
  
  .advancedProfilePicContainer,
  .advancedDescriptionContainer {
    box-sizing: border-box;
    padding: 20px;
  }
  
  .advancedProfilePicContainer {
    flex: 0 0 300px;
    max-width: 300px;
  }
  
  .advancedProfilePic {
    margin-top: 2rem;
    width: 100%;
    /* border-radius: 50%; */
  }
  
  .advancedDescriptionContainer {
    flex: 1;
  }
  
  .advancedDescription {
    text-align: justify;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .advancedProfile {
      flex-direction: column;
      align-items: center;
    }
  
    .advancedProfilePicContainer,
    .advancedDescriptionContainer {
      padding: 10px;
      max-width: 100%;
    }
  }
  