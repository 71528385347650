.main-container-content {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.incub {
    /* color: #73756f; */
    font-size: 18px;
    font-family: 'Crimson Pro', serif;
    font-weight: 800;
}


.incub-2 {
    color: #73756f;
    font-size: 22px;
    font-family: 'Crimson Pro', serif;
    font-weight: 800;
}

.text-are {
    font-weight: 400 !important;
}

.main-container-content span {
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.elise {
    /* color: #73756f; */
    /* font-size: 60px; */
    font-family: 'Crimson Pro', serif;
    font-weight: 900;
    margin-top: -2rem;
}

.elise-2 {
    color: #73756f !important;
    font-size: 26px !important;
    font-family: 'Crimson Pro', serif;
    font-weight: 600;
    margin-left: 1rem;
}










.month {
    padding: 20px 25px;
    width: 70%;
    background: #73756f;
    text-align: center;
    margin: auto;
  }
  
  .month ul {
    margin: 0;
    padding: 0;
    background: #73756f;
  }
  
  .month ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
    background: #73756f;
  }
  
  .month .prev {
    float: left;
    padding-top: 10px;
    background: #73756f;
  }
  
  .month .next {
    float: right;
    padding-top: 10px;
    background: #73756f;
  }
  
  .weekdays {
    margin: 0;
    padding: 10px 0;
    width: 70%;
    margin: auto;
    background: #73756f;
  }
  
  .weekdays li {
    display: inline-block;
    width: 13.6%;
    color: white;
    text-align: center;
    background: #73756f;
  }
  
  .days {
    padding: 10px 0;
    background: #eee;
    margin: 0;
    background: #73756f;
    margin: auto;
    width: 70%;
  }
  
  .days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    font-size:12px;
    color: white;
    background: #73756f;
  }


  .program-split {
    display: flex;
    justify-content: space-between;
}

.program-item {
    flex: 1;
    border: none;
    margin-right: 10px;
}

.program-item:last-child {
    margin-right: 0;
}
  
  .days li .active {
    padding: 5px;
    background: #923b16;
    color: white !important
  }

  h3 {
    text-align: center;
    color: #73756f;
    font-size: 20px;
  }
  
  /* Add media queries for smaller screens */
  @media screen and (max-width:720px) {
    .weekdays li, .days li {width: 13.1%;}
  }
  
  @media screen and (max-width: 420px) {
    .weekdays li, .days li {width: 12.5%;}
    .days li .active {padding: 2px;}
  }
  
  @media screen and (max-width: 290px) {
    .weekdays li, .days li {width: 12.2%;}
  }


  @media (max-width: 700px) {
    .program-split {
        flex-direction: column; 
        justify-content: flex-start; 
    }

    .program-item {
        margin-right: 0;
        margin-bottom: 20px; 
    }

    .program-item:last-child {
        margin-bottom: 0; 
    }
}


  .container-atelier-left {
    display: flex;
    align-items: center;
    margin-right: 3rem;
    width: 80%;
    margin: auto;
    margin-top: 8rem;
  }

  
  .image-container-atelier-left {
    margin-top: 4rem;
    display: flex;
  }
  
  .image-container-atelier-left img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    transition: all 0.3s ease;
    margin-right: 2rem;
  }
  
  .information-container-atelier-left {
    flex: 70%;
    color: grey;
  }
  
  .information-container-atelier-left h3 {
    color: #73756f;
    margin-bottom: 1rem;
    text-align: start;
  }

  .information-container-atelier-left h5 {
    line-height: 1;
  }

  .image-container-atelier-left img:hover {
    transform: scale(1.05);
    border: 3px solid #923b16;
  }
  .link-cv {
    color: #923b16;
    height: 4rem;
    font-size: 16px;
  }





  .testimonials-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 5rem;
}

.post {
    border: none;
    padding: 20px;
    margin-bottom: 20px;
}
  
.post-header {
    color: #5a5a5a;
    border-bottom: 2px solid #73756f;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
  
.post-title {
    font-size: 24px;
    color: #73756f;
    font-family: 'Crimson Pro', serif;
    font-weight: 900;
    font-style: italic;
}

.post-meta {
    font-style: italic;
    color: #888;
    font-family: 'Crimson Pro', serif;
}
  
.post-meta img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
}
  
.post-content {
    color: #333;
}
  