.footer-grid {
    background-color: grey; 
  }
  
  .footer-segment-group {
    border: none; 
  }
  
  .footer-segment {
    background-color: grey; 
    border: none; 
    padding: 10px; 
  }
  
  .footer-icon-segment {
    background-color: transparent;
  }
  
  .footer-icon-container {
    text-align: center;
  }
  