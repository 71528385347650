.event-card-container {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 5rem;
}

.card-hover {
    width: 360px;
    margin-right: 15px;
    margin-bottom: 30px;
}

.card-hover:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1250px) {
    .event-card-container {
        justify-content: center; 
    }

    .card-hover {
        width: 360px; 
    }
}
