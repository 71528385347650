:root {
  --background: #e3e2dd;
  --accent: #73756f;
  --otherAccent: #757b63;
  --darkBackground: #6c7c63;
}

.app-person-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    width: 100%;
}

#login-container {
    height: 400px;
    width: 350px;
    padding: 20px;
    border-radius: 5px;
    background: var(--background);
    position: relative;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
  }

.profile-img {
  height: 100px;
  width: 100px;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -25px;
  left: -25px;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}


h1 {
  font-family: 'Sriracha', arial, sans-serif;
  text-align: center;
  margin-bottom: 20px;
  color: var(--otherAccent);
}


.description {
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: 2rem;
  height: 200px
}


.social {
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  width: calc(70% - 40px);
  margin: 0 auto;
}

.social a {
  text-align: center;
  border: solid 2px var(--accent);
  width: 75px;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--accent);
}

.social a:hover {
  background: var(--accent);
  color: white;
  cursor: pointer;
}


.to-cv {
  width: 70%;
  height: 60px;
  font-size: 2rem;
  color: var(--background);
  border: none;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  border-radius: 5px;
  background: linear-gradient(45deg, var(--accent), var(--otherAccent), var(--otherAccent), var(--accent));
  background-size: 300% 300%;
  outline: none;
  transition: all 200ms ease-in-out;
  margin-top: 2rem;
}

.to-cv:hover {
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
  transform: translateY(2px);
  cursor: pointer;
}

.to-cv:active {
  box-shadow: 3px 3px 3px rgba(0,0,0,0.5);
  transform: translateY(4px);
}


footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--darkBackground);
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 30px;
  padding: 0 20px;
  margin-left: -20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

footer div {
  display: flex;
}

footer div .fa-heart {
  color: var(--accent);
}

footer div p:first-child {
  margin-right: 10px;
  border-right: 4px solid white;
  padding-right: 10px;
}
